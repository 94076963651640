import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home',
  },
  {
    id: 'sample',
    title: 'Sample',
    translate: 'MENU.SAMPLE',
    type: 'item',
    icon: 'file',
    url: 'sample',
  },
  {
    id: 'products',
    title: 'Product',
    translate: 'MENU.APPS.PRODUCT.COLLAPSIBLE',
    type: 'collapsible',
    icon: 'package',
    children: [
      {
        id: 'list',
        title: 'List',
        translate: 'MENU.APPS.PRODUCT.LIST',
        type: 'item',
        icon: 'circle',
        url: 'apps/product/product-list',
      },
      {
        id: 'manufactures',
        title: 'manufactures',
        type: 'item',
        icon: 'circle',
        url: 'apps/product/manufactures-list',
      },
      // {
      //   id: 'view',
      //   title: 'View',
      //   translate: 'MENU.APPS.PRODUCT.VIEW',
      //   type: 'item',
      //   icon: 'circle',
      //   url: 'apps/product/product-view',
      // },
      // {
      //   id: 'edit',
      //   title: 'Edit',
      //   translate: 'MENU.APPS.PRODUCT.EDIT',
      //   type: 'item',
      //   icon: 'circle',
      //   url: 'apps/product/product-edit',
      // },
    ],
  },
  {
    id: 'users',
    title: 'User',
    translate: 'MENU.APPS.USER.COLLAPSIBLE',
    type: 'collapsible',
    icon: 'user',
    children: [
      {
        id: 'list',
        title: 'List',
        translate: 'MENU.APPS.USER.LIST',
        type: 'item',
        icon: 'circle',
        url: 'apps/user/user-list',
      },
      {
        id: 'view',
        title: 'View',
        translate: 'MENU.APPS.USER.VIEW',
        type: 'item',
        icon: 'circle',
        url: 'apps/user/user-view',
      },
      {
        id: 'edit',
        title: 'Edit',
        translate: 'MENU.APPS.USER.EDIT',
        type: 'item',
        icon: 'circle',
        url: 'apps/user/user-edit',
      },
    ],
  },
];
