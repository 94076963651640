export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      SAMPLE: 'Sample',
      APPS: {
        SECTION: 'Apps & Pages',
        EMAIL: 'Email',
        CHAT: 'Chat',
        TODO: 'Todo',
        CALENDAR: 'Calendar',
        INVOICE: {
          COLLAPSIBLE: 'Invoice',
          LIST: 'List',
          PREVIEW: 'Preview',
          EDIT: 'Edit',
          ADD: 'Add',
        },
        PRODUCT: {
          COLLAPSIBLE: 'Product',
          LIST: 'List',
          VIEW: 'View',
          EDIT: 'Edit',
        },
        USER: {
          COLLAPSIBLE: 'User',
          LIST: 'List',
          VIEW: 'View',
          EDIT: 'Edit',
        },
      },
    },
  },
};
